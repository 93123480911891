import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isMobile } from '@src/utils/helpers/device';
import usePreviousDistinct from '@src/utils/hooks/usePreviousDistinct';
import DOMPurify from 'isomorphic-dompurify';
import { useSWRConfig } from 'swr';

type ConfirmationModalProps = {
    isOpen: boolean;
};

export const ConfirmationModal = ({ isOpen }: ConfirmationModalProps) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const { mutate } = useSWRConfig();

    const prevIsOpen = usePreviousDistinct(isOpen);

    if (prevIsOpen === false && isOpen === true) {
        pushToDataLayer({ event: 'custom_page_view', page_name: 'cancellation success' });
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onModalClose={async () => {
                pushToDataLayer({ event: 'custom_click', click_type: 'close' });

                // invalidate cached data
                // continue with success modal even if the data is not invalidated
                await Promise.allSettled([
                    mutate('/member/get-member'),
                    mutate('/payments/get-debits'),
                    mutate('/payments/get-transaction-log')
                ]);
            }}
            data-testid="cancel-membership-confirmation-modal"
        >
            <Heading size={isMobile() ? 2 : 5} className="mb-2">
                {t('cancellation.success.modal.title')}
            </Heading>
            <div className="flex flex-col gap-2">
                <Text size={2}>{t('cancellation.success.modal.text.sorry')}</Text>
                <div className="flex items-start space-x-2 mb-2">
                    <span className="before:content-['•'] before:text-xl" />
                    <Text size={2} className="break-words">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('cancellation.success.modal.text.email')
                                )
                            }}
                        />
                    </Text>
                </div>
                <Text size={2}>{t('cancellation.success.modal.text.thanks')}</Text>
                <Button
                    text={t('cancellation.success.modal.button.continue')}
                    onClick={async () => {
                        // invalidate cached data
                        // continue with success modal even if the data is not invalidated
                        await Promise.allSettled([
                            mutate('/member/get-member'),
                            mutate('/payments/get-debits'),
                            mutate('/payments/get-transaction-log')
                        ]);
                        setIsModalOpen(false);
                    }}
                    data-testid="cancel-membership-confirmation-modal-button"
                    className="mt-4"
                />
            </div>
        </Modal>
    );
};
