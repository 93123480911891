import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { LastPaymentData } from '@src/utils/helpers/memberHelpers';
import {
    isPrepaidInContract,
    isRegularInContract,
    PaymentInterval
} from '@src/utils/helpers/priceHelpers';
import { useFirstMountState } from '@src/utils/hooks/useFirstMountState';
import DOMPurify from 'isomorphic-dompurify';

export type CancelCheckContentProps = {
    memberName: string;
    membershipEndDate: string;
    paymentInterval: PaymentInterval;
    contractLength: PaymentInterval; // 4 weeks is flex
    showCreditNotice?: boolean;
    lastPaymentData: LastPaymentData | null;
    contractEndDate: string | null;
};

export function CancelCheckContent(props: CancelCheckContentProps) {
    const { t } = useTranslation(undefined, { lng: 'en' });

    const yearlyContract4weeksPmsInContract = isRegularInContract(
        props.paymentInterval,
        props.contractLength,
        props.contractEndDate
    );

    const yearlyContract52weeksPmsInContract = isPrepaidInContract(
        props.paymentInterval,
        props.contractLength,
        props.contractEndDate
    );

    return (
        <>
            <Text data-testid="cancel-overview-page-payment-content" className="mt-xs" size={2}>
                {yearlyContract4weeksPmsInContract ? (
                    <Trans
                        i18nKey="cancellation.overview.page.payment.52weeks"
                        values={{
                            endDate: props.membershipEndDate,
                            paymentsLeft: props.lastPaymentData?.numberOfPaymentsLeft ?? 0
                        }}
                        components={{
                            bold: <strong />,
                            servicelink: <ServiceLink />
                        }}
                    />
                ) : yearlyContract52weeksPmsInContract ? (
                    <Trans
                        i18nKey="cancellation.overview.page.payment.prepayment"
                        values={{ endDate: props.membershipEndDate }}
                        components={{
                            bold: <strong />,
                            servicelink: <ServiceLink />
                        }}
                    />
                ) : (
                    <Trans
                        i18nKey="cancellation.overview.page.payment.finalPayment"
                        components={{
                            bold: <strong />,
                            servicelink: <ServiceLink />
                        }}
                    />
                )}
            </Text>
            {props.showCreditNotice && <CreditNotice />}
            <Text size={2} className="mt-s">
                <strong className="font-heading-treble font-bold">{t('379')}</strong>
            </Text>

            <Table {...props} />
            {yearlyContract4weeksPmsInContract && <Subscript />}
        </>
    );
}

type TableProps = CancelCheckContentProps;

function Table(props: TableProps) {
    const { t } = useTranslation();

    const tableData = [[t('151'), <strong key={props.memberName}>{props.memberName}</strong>]];

    const yearlyContract4weeksPmsInContract = isRegularInContract(
        props.paymentInterval,
        props.contractLength,
        props.contractEndDate
    );

    const lastPaymentDate = props.lastPaymentData?.date;

    if (yearlyContract4weeksPmsInContract && lastPaymentDate) {
        tableData.push([
            t('membership.cancellation.payment.last_payment') + '*',
            <strong key={lastPaymentDate}>{lastPaymentDate}</strong>
        ]);
    }

    tableData.push([
        t('cancellation.overview.page.end.date'),
        <strong key={props.membershipEndDate}>{props.membershipEndDate}</strong>
    ]);

    return <TableFlex data={tableData} />;
}

function Subscript() {
    const { t } = useTranslation();

    return (
        <Text size={2} className="mt-xs">
            * {t('cancellation.overview.page.footnote')}
        </Text>
    );
}

function ServiceLink({ children }: { children?: React.ReactNode }) {
    return (
        <Link
            to="/service"
            onClick={() =>
                pushToDataLayer({
                    event: 'custom_click',
                    click_type: 'to faq'
                })
            }
            className="text-orange underline"
            data-testid="cancel-membership__faq__link"
        >
            {children}
        </Link>
    );
}

function CreditNotice() {
    const { t } = useTranslation();
    const isFirstMount = useFirstMountState();

    if (isFirstMount) {
        pushToDataLayer({
            event: 'custom_page_view',
            page_name: 'credit copy displayed'
        });
    }

    return (
        <Text className="mt-s mb-xxs" size={2}>
            <span
                data-testid="cancel-membership-check-credit-notice"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t('cancellation.overview.page.refund'))
                }}
            />
        </Text>
    );
}
