import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Skeleton, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { CancellationData } from '@src/pages/CancelMembership/CancelMembership.types';
import { Path } from '@src/router/CancelMembership.router';
import { cancelMembership } from '@src/services/data/cancelMembership.data';
import { useMember } from '@src/services/member/MemberProvider';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { formatDate } from '@src/utils/helpers/date';
import { formatMemberName, getLastPaymentData } from '@src/utils/helpers/memberHelpers';
import { DebitsProps } from '@src/utils/hooks/api/types/debits';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useFirstMountState } from '@src/utils/hooks/useFirstMountState';
import { DateTime } from 'luxon';

import { CancelCheckContent } from './Components/CancelCheckComponents';

export default function CancelMembershipCheck(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { chosenReason, reasonDescription } = useCancellationContext();
    const { state: member } = useMember();

    const {
        data,
        isLoading: dataLoading,
        isError
    } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    const { data: debits } = useFetch<DebitsProps>('/payments/get-debits?maxRows=13');

    const isFirstMount = useFirstMountState();
    if (dataLoading) return <Skeleton type="page" />;
    if (isError) navigate(Path.Base + Path.Reasons);

    if (!chosenReason?.reason) navigate(Path.Base + Path.Reasons);
    if (isFirstMount) {
        pushToDataLayer({ event: 'custom_page_view', page_name: 'cancellation overview' });
    }

    async function cancelMembershipHandle() {
        if (isLoading) return;

        const isInvalidData = !member.id_g || !chosenReason?.reason;
        if (isInvalidData) return;

        setIsLoading(true);

        try {
            pushToDataLayer({ event: 'custom_click', click_type: 'confirm cancellation' });

            await cancelMembership({
                peopleMembershipId: member.id_g,
                cancelReason: chosenReason.reason,
                reasonDescription: reasonDescription
            });

            navigate('/membership', { state: { showCancellationSuccessModal: true } });
        } catch {
            pushToDataLayer({ event: 'custom_error', page_name: 'cancel membership' });
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    const inContract = DateTime.fromISO(member.contract_end_date_g) > DateTime.now();
    const membershipEndDate = formatDate(
        inContract ? member.contract_end_date_g : data.date.cancelDate
    );

    return (
        <>
            <div
                data-cy="cancel-membership__check"
                data-testid="cancel-membership__check"
                className="flex flex-col h-full"
            >
                <div className="hidden lg:block">
                    {/* cancel membership & Sorry that you want to stop */}
                    <Header
                        data-cy="cancel-membership__check__header"
                        data-testid="cancel-membership__check__header"
                        title={t('102')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />
                </div>
                <Section>
                    <Box variant="white">
                        <Heading size={3} className="block lg:hidden mb-6">
                            {t('102')}
                        </Heading>
                        <Heading size={5}>{t('cancellation.overview.page.title')}</Heading>
                        <CancelCheckContent
                            memberName={formatMemberName(member)}
                            membershipEndDate={membershipEndDate}
                            paymentInterval={member.payment_plan.interval}
                            showCreditNotice={data.date.correctionAmount !== 0}
                            contractLength={member.payment_plan.minimal_contract_period}
                            lastPaymentData={getLastPaymentData(debits, data.date.cancelDate)}
                            contractEndDate={member.contract_end_date_g}
                        />
                    </Box>
                </Section>

                <Section
                    data-cy="cancel-membership__check__buttons"
                    data-testid="cancel-membership__check__buttons"
                    className="flex justify-center items-center mt-auto mb-s"
                >
                    <div className="w-[315px] flex-col justify-center flex">
                        {/*  Something went wrong, please try again */}
                        {error && (
                            <Text
                                data-cy="cancel-membership__check__no-choice-alert"
                                data-testid="cancel-membership__check__no-choice-alert"
                                className="!block text-red mb-[20px] text-center"
                                size={2}
                            >
                                {t('1527')}
                            </Text>
                        )}

                        {/* next button */}
                        <Button
                            data-cy="cancel-membership__check__button-next"
                            data-testid="cancel-membership__check__button-next"
                            onClick={cancelMembershipHandle}
                            disabled={isLoading}
                            loading={isLoading}
                            className="mb-[10px]"
                            text={t('cancellation.overview.page.confirm')}
                        />

                        {/* go back */}
                        <Button
                            data-cy="cancel-membership__check__button-back"
                            data-testid="cancel-membership__check__button-back"
                            onClick={() => {
                                pushToDataLayer({ event: 'custom_click', click_type: 'go back' });
                                navigate(Path.Base + Path.Reasons);
                            }}
                            color="naked"
                            text={t('162')}
                        />
                    </div>
                </Section>
            </div>
        </>
    );
}
