import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonList } from '@src/components/ButtonList/ButtonList';
import { IconButton } from '@src/components/Buttons/IconButton';
import { Icon } from '@src/components/Icons/Icons';
import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { CancelMembershipModal } from '@src/pages/Membership/Membership/components/Modals/CancelMembership.modal';
import { useMember } from '@src/services/member/MemberProvider';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { getLocalDateString } from '@src/utils/helpers/date';
import {
    canCancelMembership,
    canChangeMembership,
    hasClubChangeAccess
} from '@src/utils/helpers/memberHelpers';

export const ChangeMembership = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    if (canChangeMembership(member) === false) return null;

    return (
        <div className="mt-14">
            <ButtonList title={t('1633')}>
                <ChangeHomeClubButton />
                <ChangeMembershipButton />
                <CancelMembershipButton />
            </ButtonList>
        </div>
    );
};

const ChangeHomeClubButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: member } = useMember();

    if (member.membership_type_g !== Membership.BASIC) return null;

    const notAllowedSubtitle = t('change_home_club.not_allowed').replace(
        '%date%',
        getLocalDateString(member.clubChangePossibleFrom)
    );
    const subtitle = hasClubChangeAccess(member) ? '' : notAllowedSubtitle;

    return (
        <IconButton
            icon={<Icon id="home-club" />}
            title={t('change_home_club.title')}
            disabled={hasClubChangeAccess(member) === false}
            subtitle={subtitle}
            onClick={() => navigate('/membership/change-home-club')}
            data-testid="change-home-club-button"
        />
    );
};

const CancelMembershipButton = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const [isCancelMembershipModalOpen, setIsCancelMembershipModalOpen] = useState(false);

    if (canCancelMembership(member) === false) return null;

    return (
        <>
            <IconButton
                icon={<Icon id="cancel" />}
                title={t('102')}
                onClick={() => {
                    pushToDataLayer({
                        event: 'custom_click',
                        click_type: 'start cancel membership'
                    });
                    setIsCancelMembershipModalOpen(true);
                }}
                data-cy="cancel-membership-button"
                data-testid="cancel-membership-button"
            />
            <CancelMembershipModal
                isOpen={isCancelMembershipModalOpen}
                onClose={() => setIsCancelMembershipModalOpen(false)}
            />
        </>
    );
};

const ChangeMembershipButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <IconButton
            icon={<Icon id="change" />}
            title={t('1625')}
            onClick={() => navigate('/change-membership')}
            data-cy="change-membership-button"
            data-testid="change-membership-button"
        />
    );
};
