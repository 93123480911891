interface PageViewEvent {
    event: 'custom_page_view';
    page_name: string;
}
interface ClickEvent {
    event: 'custom_click';
    click_type: string;
}

interface ErrorEvent {
    event: 'custom_error';
    page_name: string;
}

type DataLayerEvent = PageViewEvent | ClickEvent | ErrorEvent;

export function pushToDataLayer(data: DataLayerEvent): void {
    window.dataLayer ??= [];
    window.dataLayer.push(data);
}
