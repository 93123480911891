import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, List, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';
import { Path } from '@src/router/CancelMembership.router';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isMobile } from '@src/utils/helpers/device';
import usePreviousDistinct from '@src/utils/hooks/usePreviousDistinct';
import DOMPurify from 'isomorphic-dompurify';

type CancelMembershipModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CancelMembershipModal = ({ isOpen, onClose }: CancelMembershipModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const prevIsOpen = usePreviousDistinct(isOpen);

    if (prevIsOpen === false && isOpen === true) {
        pushToDataLayer({ event: 'custom_page_view', page_name: 'are you sure modal' });
    }

    if (isOpen === false) return null;

    return (
        <Modal
            isOpen={isOpen}
            onModalClose={() => {
                pushToDataLayer({ event: 'custom_click', click_type: 'close' });
                onClose();
            }}
            data-testid="cancel-membership-modal"
            showScrollbars={false}
        >
            <Heading size={isMobile() ? 2 : 5} className="mb-2">
                {t('membership.cancellation.modal_title')}
            </Heading>
            <div className="flex flex-col gap-4">
                <Text size={2}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('membership.cancellation.modal.text'))
                        }}
                    />
                </Text>
                <List
                    listStyle={{ color: 'anthracite', size: 2, bold: false }}
                    itemStyle={{ color: 'anthracite', size: 2 }}
                    type="unordered"
                    className="pr-4"
                    listItems={t('membership.cancellation.modal.benefits.list')
                        .split('\\n')
                        .map((item, index) => (
                            <div key={index} className="flex items-start space-x-2 mb-2">
                                <span className="before:content-['•'] before:text-xl" />
                                <Text size={2} className="break-words">
                                    {item}
                                </Text>
                            </div>
                        ))}
                />
                <Button
                    variant="primary"
                    onClick={() => {
                        pushToDataLayer({ event: 'custom_click', click_type: 'proceed to cancel' });
                        navigate(Path.Base + Path.Reasons);
                    }}
                    data-cy="cancel-membership__modal__confirm"
                    data-testid="cancel-membership-modal-proceed"
                >
                    {t('membership.cancellation.proceed')}
                </Button>
                <Button
                    theme="light"
                    variant="primary"
                    onClick={() => {
                        pushToDataLayer({ event: 'custom_click', click_type: 'keep membership' });
                        onClose();
                    }}
                    data-testid="cancel-membership-modal-cancel"
                >
                    {t('membership.cancellation.keep_membership')}
                </Button>
            </div>
        </Modal>
    );
};
