import { useEffect, useRef } from 'react';

/**
 * Returns true if component is just mounted (on first render) and false otherwise.
 * copied from: https://github.com/streamich/react-use/blob/ad33f76dfff7ddb041a9ef74b80656a94affaa80/src/useFirstMountState.ts
 */
export function useFirstMountState(): boolean {
    const isFirst = useRef(true);

    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
        }
    }, []);

    return isFirst.current;
}
