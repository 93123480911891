import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { RadioButton } from '@src/components/Form/RadioButton/RadioButton.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import {
    CancellationData,
    CancellationReasonProps
} from '@src/pages/CancelMembership/CancelMembership.types';
import { Path } from '@src/router/CancelMembership.router';
import { useMember } from '@src/services/member/MemberProvider';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isSecondary } from '@src/utils/helpers/memberHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { useFirstMountState } from '@src/utils/hooks/useFirstMountState';

import { DowngradeModal } from './Modals/DowngradeModal';

const downgradeReasons = ['cost', 'other_reason', 'workout_other_place'];

export default function CancelMembershipReasons(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: member } = useMember();
    const { upscaleMemberships } = useMBFFeatures();
    const isFirstMount = useFirstMountState();
    const [showChooseReasonsAlert, setShowChooseReasonsAlert] = useState(false);
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const { data, isLoading, isError } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );
    const {
        chosenReason,
        reasonDescription,
        setReasonDescription,
        setChosenReason,
        resetCancelMembershipCache
    } = useCancellationContext();

    // do not allow to cancel membership if the member is a secondary member
    if (isSecondary(member)) navigate('/membership');

    const sortedReasons = useMemo(() => {
        return data?.reasons.sort((a, b) => {
            // ensure 'other_reason' is always at the bottom
            if (a.reason === 'other_reason') {
                return 1;
            }
            if (b.reason === 'other_reason') {
                return -1;
            }

            return Math.random() - 0.5;
        });
    }, [data]);

    if (isLoading) return <Skeleton type="page" />;
    if (isError) navigate(Path.Base + Path.Reasons);

    if (isFirstMount) {
        pushToDataLayer({ event: 'custom_page_view', page_name: 'cancellation reasons' });
    }

    function pickReason(pickedChoice: CancellationReasonProps) {
        if (pickedChoice !== chosenReason) setReasonDescription('');
        setChosenReason(pickedChoice);
        setShowChooseReasonsAlert(false);
    }

    function goToCheckPage() {
        if (chosenReason?.reason === undefined) return setShowChooseReasonsAlert(true);
        if (isUpscaleMembership() && downgradeReasons.includes(chosenReason.reason)) {
            return setShowDowngradeModal(true);
        }

        pushToDataLayer({ event: 'custom_click', click_type: 'next to cancel overview' });
        navigate(Path.Base + Path.Check);
    }

    function goBackToMembership() {
        pushToDataLayer({ event: 'custom_click', click_type: 'go back' });
        resetCancelMembershipCache();
        navigate('/membership');
    }

    function isUpscaleMembership() {
        const expensiveMemberships = upscaleMemberships?.memberships;

        return (
            Array.isArray(expensiveMemberships) &&
            expensiveMemberships.includes(member.membership_type_g)
        );
    }

    return (
        <>
            {member.isAllIn ? (
                <div
                    data-cy="cancel-membership__reasons__houston"
                    data-testid="cancel-membership__reasons__houston"
                >
                    {/* CANCEL MEMBERSHIP */}
                    <Header
                        data-cy="cancel-membership__reasons__header"
                        data-testid="cancel-membership__reasons__header"
                        title={t('117')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />
                    {/* You cannot cancel your membership */}
                    <Text className="mt-xs" size={1}>
                        {t('1809')}
                    </Text>
                </div>
            ) : (
                <div data-cy="cancel-membership__reasons" data-testid="cancel-membership__reasons">
                    {/* cancelmembership & We are sorry to hear that... */}
                    <div className="hidden lg:block">
                        <Header
                            data-cy="cancel-membership__reasons__header"
                            data-testid="cancel-membership__reasons__header"
                            title={t('117')}
                            text={t('118')}
                            image={<LineSVG id="vertical-lines" height={267} />}
                        />
                    </div>
                    <Section
                        data-cy="cancel-membership__reasons__section"
                        data-testid="cancel-membership__reasons__section"
                    >
                        {/* why to cancel ? */}
                        <div className="px-s md:p-0">
                            <Heading size={3} className="block lg:hidden mb-6">
                                {t('117')}
                            </Heading>
                            <Heading
                                data-cy="cancel-membership__reasons__section-heading"
                                data-testid="cancel-membership__reasons__section-heading"
                                className="mb-xs"
                                size={5}
                            >
                                {t('membership.cancellation.reasons.title')}
                            </Heading>
                            <Text size={2}>{t('membership.cancellation.reasons.subtitle')}</Text>
                        </div>

                        <div
                            data-cy="cancel-membership__reasons__reasons"
                            data-testid="cancel-membership__reasons__reasons"
                            className="relative p-s md:p-0 my-xs"
                        >
                            {/* loading skeleton */}
                            {isLoading && (
                                <div>
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                    <Skeleton type="button" className="mb-1" />
                                </div>
                            )}
                            {/* We are experiencing a technical issue. Sorry for that! Please try again later. */}
                            {isError && <Text size={2}>{t('528')}</Text>}
                            {sortedReasons.map((reason: CancellationReasonProps) => {
                                return (
                                    <div key={reason.reason} className="my-1">
                                        <div
                                            className="flex cursor-pointer items-center px-s py-xs transition-border duration-300 bg-jersey-grey"
                                            data-cy="cancel-membership__reasons__reason"
                                            data-testid={`cancel-membership__reasons__reason-${reason.reason
                                                .toLowerCase()
                                                .replace(/ /g, '-')}`}
                                            onClick={() => pickReason(reason)}
                                        >
                                            <RadioButton
                                                name={reason.reason}
                                                label={reason.text}
                                                checked={chosenReason?.reason == reason.reason}
                                            />
                                        </div>
                                        {chosenReason?.reason == reason.reason && (
                                            <div className="bg-jersey-grey p-1">
                                                <textarea
                                                    onChange={({ target }) =>
                                                        setReasonDescription(target.value)
                                                    }
                                                    placeholder={t(
                                                        'membership.cancellation.reasons.description-input'
                                                    )}
                                                    className="p-4 w-full focus:outline-none focus:border-0 focus:ring-0 text-regular-s"
                                                    defaultValue={reasonDescription}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <div className="absolute right-[-60px] bottom-[-60px]">
                                <LineSVG id="curved-lines" height={200} />
                            </div>
                        </div>
                    </Section>

                    <Section
                        data-cy="cancel-membership__reasons__buttons"
                        data-testid="cancel-membership__reasons__buttons"
                        className="flex justify-center items-center"
                    >
                        <div className="w-[315px] flex-col justify-center flex">
                            {/*Please select your reasons! */}
                            <Text
                                data-cy="cancel-membership__reasons__button-no-choice-alert"
                                data-testid="cancel-membership__reasons__button-no-choice-alert"
                                className={`text-red text-center mb-2.5 transition-opacity duration-300 ${
                                    showChooseReasonsAlert ? 'opacity-100' : 'opacity-0'
                                }`}
                                size={2}
                            >
                                {t('306')}
                            </Text>

                            {/* next button */}
                            <Button
                                data-cy="cancel-membership__reasons__button-forward"
                                data-testid="cancel-membership__reasons__button-forward"
                                onClick={goToCheckPage}
                                className="mb-2.5"
                                text={t('128')}
                            />

                            {/* go back */}
                            <Button
                                data-cy="cancel-membership__reasons__button-back"
                                data-testid="cancel-membership__reasons__button-back"
                                onClick={goBackToMembership}
                                color="naked"
                                text={t('162')}
                            />
                        </div>
                    </Section>
                </div>
            )}
            <DowngradeModal
                isOpen={showDowngradeModal}
                onClose={() => setShowDowngradeModal(false)}
            />
        </>
    );
}
