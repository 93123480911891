import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { ChangeMembership } from '@src/components/ChangeMembership/ChangeMembership';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { ConfirmationModal } from '@src/pages/CancelMembership/Modals/ConfirmationModal';
import { UnableToChangeMembershipModal } from '@src/pages/ChangeMembership/components/UnableToChangeMembershipModal';
import { AddOns } from '@src/pages/Membership/Membership/components/AddOns/AddOns';
import { DownloadContract } from '@src/pages/Membership/Membership/components/DownloadContract/DownloadContract';
import { MembershipDetails } from '@src/pages/Membership/Membership/components/MembershipDetails/MembershipDetails';
import { SecondaryMember } from '@src/pages/Membership/Membership/components/SecondaryMember/SecondaryMember';
import { ReactComponent as HeaderImage } from '@src/pages/Membership/Membership/images/header.svg';
import { isMemberAllowedToFreeze } from '@src/services/freeze.service';
import { useMember } from '@src/services/member/MemberProvider';
import { canUseSecondaryMemberFeature } from '@src/services/secondaryMember.service';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isMobile } from '@src/utils/helpers/device';
import { canRenewMembership, hasMembership, isEmployee } from '@src/utils/helpers/memberHelpers';
import { useArticles } from '@src/utils/hooks/api/useArticles';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { RejectReason } from '@src/utils/hooks/useChangeMembershipGuard';

import ChosenCompensations from '../../../../components/compensation/ChosenCompensations';
import { ChangeAccess } from './components/ChangeAccess/ChangeAccess';
import { FreezeMembershipComponent } from './components/FreezeMembership/FreezeMembershipComponent';
import { ChangeMembershipModal } from './components/Modals/ChangeMembership.modal';
import { RenewMembershipModal } from './components/Modals/RenewMembership.modal';
import { PrepaidRenewalSection } from './components/PrepaidRenewalSection/PrepaidRenewalSection';

export type MembershipNavState = {
    showCancellationSuccessModal: boolean;
    changeMembershipGuard?: RejectReason;
};

export const Membership = () => {
    const { t } = useTranslation();
    const { articles } = useArticles();
    const { state: member } = useMember();
    const { state: navState }: { state: MembershipNavState } = useLocation();
    const { changeDevice, prepaidRenewal } = useMBFFeatures();
    const [currentMemberArticles, setCurrentMemberArticles] = useState<string[]>();
    const ranOnce = useRef(false);

    useEffect(() => {
        if (articles?.boughtArticles) {
            const articleIds = articles.boughtArticles.map(({ articleId }) => articleId);
            setCurrentMemberArticles(articleIds);
        }

        if (ranOnce.current === false) {
            ranOnce.current = true;
            pushToDataLayer({ event: 'custom_page_view', page_name: 'view membership page' });
        }
    }, [articles]);

    const Image = () => {
        return (
            <div className="absolute top-0 right-0">
                <HeaderImage />
            </div>
        );
    };

    const frozenInformationBlock = (
        <InformationBlock>
            <Text size={2}>{t('freeze.infoblock.text')}</Text>
            <ButtonLink to="/membership/freeze">{t('freeze.infoblock.button')}</ButtonLink>
        </InformationBlock>
    );

    const isMemberFrozen = member?.peopleMembership?.membershipStatus === 'Frozen';

    const allowFreezeToggle = member?.freeze?.allowFreeze;

    return (
        <Section>
            <div className="hidden lg:block" data-testid="membership-page-header">
                <Header title={t('40')} text={t('96')} image={<Image />} />
            </div>
            <Heading size={3} className="block lg:hidden ms-4">
                {t('40')}
            </Heading>
            {isMemberFrozen && (
                <>
                    {isMobile() ? (
                        <Section className="mt-4">
                            <Wrapper>{frozenInformationBlock}</Wrapper>
                        </Section>
                    ) : (
                        <div className="mt-[60px]">{frozenInformationBlock}</div>
                    )}
                </>
            )}

            {prepaidRenewal && canRenewMembership(member) && <PrepaidRenewalSection />}

            {member.chosenCompensations && member.chosenCompensations.length > 0 && (
                <ChosenCompensations />
            )}

            {hasMembership(member) && (
                <Section>
                    <MembershipDetails />
                </Section>
            )}

            {changeDevice &&
                member.accessType &&
                hasMembership(member) &&
                canUseSecondaryMemberFeature(currentMemberArticles) && (
                    <Section>
                        <SecondaryMember />
                    </Section>
                )}

            {changeDevice && member.accessType && hasMembership(member) && (
                <Section>
                    <ChangeAccess />
                </Section>
            )}

            {!hasMembership(member) && <Text size={2}>{t('451')}</Text>}

            <DownloadContract />
            <ChangeMembership />

            {isMemberAllowedToFreeze(member, articles?.boughtArticles) && allowFreezeToggle && (
                <FreezeMembershipComponent data-cy="freeze-membership-button" />
            )}

            {/* addons */}
            {hasMembership(member) && !isEmployee(member) && !isMemberFrozen && <AddOns />}

            <Section>
                <FAQ category="membership" />
            </Section>

            <ChangeMembershipModal />

            <RenewMembershipModal />

            <UnableToChangeMembershipModal
                isOpen={!!navState?.changeMembershipGuard}
                reason={navState?.changeMembershipGuard}
            />

            <ConfirmationModal isOpen={navState?.showCancellationSuccessModal} />
        </Section>
    );
};
